<template>
  <component v-bind:is="pricingPage" />
</template>

<script>
import { AUTH_NAMES, SUBSCRIBE_STEPS_NAMES } from "@/helpers/rout-constants";
import { mapGetters } from "vuex";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";
import CookieHelper from "@/helpers/cookie-helper";

export default {
  name: "SubscribePlans",
  components: {},
  data() {
    return {};
  },
  computed: {
    pricingPage() {
      let pricing = CookieHelper.getFlag("pricing", "01");

      if (pricing == "02") {
        return () => import(`@/views/subscribe/components/PricingFormV2`);
      } else {
        return () => import(`@/views/subscribe/components/PricingFormV1`);
      }
    },
  },
  methods: {},

  async created() {},
};
</script>

<style scoped lang="scss">
.subscribe-section {
  padding: 32px 0 80px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding: 40px 0 105px 0;
    text-align: center;
  }

  &__title {
    @media (max-width: map_get($grid-breakpoints, lg)) {
      font-size: 24px;
    }
    @media (max-width: 375px) {
      font-size: 20px !important;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
    margin-top: 16px;
    margin-bottom: 32px;
    @media (max-width: 375px) {
      font-size: 12px !important;
      margin-top: 8px !important;
      margin-bottom: 14px !important;
    }
  }
}
</style>
